import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { siteColors } from "../constants/colors";
import { useViewport } from "../utils/viewport-hook";
import breakpoints from "../constants/breakpoints";
import {
    Band,
    BandTextContainer,
    BandTextHeader,
    BandBodyText,
} from "./band";

const SpacerDiv = styled.div`
   width: 100%;
`;

const TextContents = () => (
        <BandTextContainer>
        <BandTextHeader>
        Versatility
    </BandTextHeader>
        <BandBodyText>
        <p>
        No matter what type of rigging you do, Contour can solve problems you face every day.
        </p>
        <p>
        Use Contour Bones to rig a cartoony character with infinitely stretchable limbs, or use them to construct a pseudo-muscle system for a semi-realistic character. Deform a mesh with Contour, use it to drive the rigid meshes of a robotic tentacle, or use it to drive conventional Maya joints for game engine export.
        </p>
        </BandBodyText>
        </BandTextContainer>
);

const MonsterBackgroundImage = ({ className, children, gradient }) => {
    const { monster } = useStaticQuery(
        graphql`
      query {
        monster: file(relativePath: { eq: "monster.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
    );

    const monsterImageData = monster.childImageSharp.fluid;

    const gradientImageStack = [
        `linear-gradient( to right, rgba(4, 8, 20, 0) 0%, rgba(4, 8, 20, 0) 35%, rgba(4, 8, 20, 0.75) 55% )`,
        monsterImageData,
    ];

    return (
        <BackgroundImage
          className={className}
        fluid={gradient ? gradientImageStack : monsterImageData}
          role="img"
        >
          {children}
        </BackgroundImage>
    );
};

const AnchoredMonsterBackgroundImage = styled(MonsterBackgroundImage)`
    background-position: left bottom;
`;

const StyledSpacerMonsterImage = styled(AnchoredMonsterBackgroundImage)`
   flex-grow: 1;
   height: 568px;
`;

const MonsterBand = () => {
    const screenWidth = useViewport().width;

     return  screenWidth > breakpoints.desktopSmallHD ? (
         <AnchoredMonsterBackgroundImage gradient>
             <Band
         rowHeight="750px"
          >
             <SpacerDiv screenWidth={screenWidth}/>
             <TextContents />
             </Band>
             </AnchoredMonsterBackgroundImage>
     ) : (
             <Band
         background={siteColors.darkest}
         columnHeight="900px"
         >
             <TextContents />
             <StyledSpacerMonsterImage />
             </Band>
     );
};

export default MonsterBand;
