import React from "react";
import styled from "styled-components";
import Card from '@material-ui/core/Card';
import { siteColors } from "../constants/colors";
import breakpoints from "../constants/breakpoints";
import ConstraintsCardImage from "../images/featureIcons/constraints.png";
import IKCardImage from "../images/featureIcons/ik.png";
import PythonCardImage from "../images/featureIcons/python.png";

const OuterContainer = styled.div`
padding-top: 2em;
   display: flex;
   justify-content: center;
  align-items: center;
   background-color: ${siteColors.darkest};
`;

const CardContainer = styled.div`
   display: flex;
   width: 100%;
   max-width: 1300px;
   justify-content: space-around;
   color: ${siteColors.light};
  @media (max-width: ${ breakpoints.desktopSD }px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FeatureCard = styled(Card)`
  width: 380px;
  color: ${siteColors.darkest};
  background-color: ${siteColors.main};
  padding: 1.0em;
  @media (max-width: ${ breakpoints.desktopSmallHD }px) {
    width: 320px;
  }
  @media (max-width: ${ breakpoints.desktopXSmallHD }px) {
    width: 280px;
  }
  @media (max-width: ${ breakpoints.desktopSD }px) {
    width: 640px;
    margin-bottom: ${ props => props.last ? "0" : "4em" };
  }
  @media (max-width: ${ breakpoints.iPad }px) {
    width: 480px;
    margin-bottom: ${ props => props.last ? "0" : "4em" };
  }
  @media (max-width: ${ breakpoints.xLargePhone }px) {
    width: 380px;
    margin-bottom: ${ props => props.last ? "0" : "4em" };
  }
  @media (max-width: ${ breakpoints.mediumPhone }px) {
    width: 320px;
    margin-bottom: ${ props => props.last ? "0" : "4em" };
  }
`;

const CardHeading = styled.div`
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 0.5em;
`;

const CardText = styled.div`

`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const MainFeatureCards = () => (
  <OuterContainer>
    <CardContainer>

      <FeatureCard>
        <CardHeading>Integrated Constraints</CardHeading>
        <CardText>
          Contour's constraints use the same techniques as its deformer,
          making it easy to drive transforms, and rig complex relationships
          between Contour chains.
        </CardText>
        <ImageContainer>
          <img src={ConstraintsCardImage} alt="Contour Rig Tools constraints" />
        </ImageContainer>
      </FeatureCard>

      <FeatureCard>
        <CardHeading>Improved IK and Aim Handles</CardHeading>
        <CardText>
          Built-in IK comes with stretchy and anti-ik-pop features out of the box.
          Aim handles with stretch make it easy to construct cartoony rigs.
        </CardText>
        <ImageContainer>
          <img src={IKCardImage} alt="Contour Rig Tools IK system" />
        </ImageContainer>
      </FeatureCard>

      <FeatureCard last>
        <CardHeading>Python API</CardHeading>
        <CardText>
          Create, modify, and query Contour nodes using a simple Python API. The API accepts
          strings, pyNodes, and MObjects, making it easy to use no matter how you script Maya.
        </CardText>
        <ImageContainer>
          <img src={PythonCardImage} alt="Contour Rig Tools has a Python API" />
        </ImageContainer>
      </FeatureCard>

    </CardContainer>
  </OuterContainer>
);

export default MainFeatureCards;
