import React from "react";
import styled from "styled-components";
import Img from 'gatsby-image';
import { siteColors } from "../constants/colors";
import breakpoints from "../constants/breakpoints";
import {
  Band,
  BandTextContainer,
  BandTextHeader,
  BandBodyText,
} from "./band";

const AcrobatContainer = styled.div`
  width: 100%;
  line-height: 0;
  display: flex;
  justify-content: center;
  padding-top: 3em;
  padding-bottom: 2em;
  padding-left: 1em;
  margin-right: -7em;
`;

const AcrobatInnerContainer = styled.div`
  width: 720px;
  height: 512px;
  min-width: 720px;
  min-height: 512px;
  @media (max-width: ${ breakpoints.desktopMediumHD }px) and (min-width: ${ breakpoints.desktopSmallHD + 1}px) {
     width: 540px;
     height: 384px;
     min-width: 540px;
     min-height: 384px;
  }
  @media (max-width: ${ breakpoints.iPad }px) {
     width: 540px;
     height: 384px;
     min-width: 540px;
     min-height: 384px;
  }
  @media (max-width: ${ breakpoints.xLargePhone }px) {
     width: 360px;
     height: 256px;
     min-width: 360px;
     min-height: 256px;
  }
`;

const Acrobat = ({ imageData }) => (
  <AcrobatContainer>
        <AcrobatInnerContainer>
        <Img fluid={imageData} alt="Wireframe rendering of a superhero deformed by Contour Rig Tools" />
        </AcrobatInnerContainer>
  </AcrobatContainer>
);

const SecondBand = (props) => (
  <Band background={siteColors.darkest} reverse>
        <Acrobat imageData={props.data.acrobat720.childImageSharp.fluid}/>
    <BandTextContainer>
      <BandTextHeader>
        Powerful Rigging Tools
      </BandTextHeader>
      <BandBodyText>
        Contour Rig Tools&rsquo; powerful spline-based deformer creates deformation of
        unparallelled quality. Pull Rig Tools rigs into almost any shape&mdash;Rig
        Tools will produce robust, volume-aware deformation in even the most extreme
        poses.
      </BandBodyText>

      <BandTextHeader>
        Simple Workflows
      </BandTextHeader>
      <BandBodyText>
        Contour Rig Tools&rsquo; simple, elegant workflows greatly reduce the
        complexity of character rigging, saving TDs days or even weeks of work.
      </BandBodyText>
    </BandTextContainer>
  </Band>
);

export default SecondBand;
