import React from "react";
import styled from "styled-components";
import { siteColors } from "../constants/colors";
import JinxAnimation320 from "../images/jinxwire/jinx_320w_q50.webp";
import JinxAnimation414 from "../images/jinxwire/jinx_414w_q50.webp";
import JinxAnimation604 from "../images/jinxwire/jinx_604w_q50.webp";
import JinxAnimation768 from "../images/jinxwire/jinx_768w_q50.webp";
import JinxAnimation960 from "../images/jinxwire/jinx_960w_q50.webp";
import JinxAnimation1024 from "../images/jinxwire/jinx_1024w_q50.webp";
import JinxAnimation1280 from "../images/jinxwire/jinx_1280w_q50.webp";
import JinxSwoopImage from "../images/jinxwire/jinx_swoop.png";
import {
  Band,
  BandTextContainer,
  BandTextHeader,
  BandBodyText,
} from "./band";

const JinxContainer = styled.div`
  line-height: 0;
  display: flex;
  justify-content: left;
width: 100%;
`;

const JinxPicture = styled.picture`
    width: 100%;
    height: auto;
`;

const JinxImage = styled.img`
    width: 100%;
    height: auto;
`;

const JinxWireframe = () => (
  <JinxContainer>
        <JinxPicture>
    <source
    srcSet={`
        ${JinxAnimation320} 320w,
        ${JinxAnimation414} 414w,
        ${JinxAnimation604} 604w,
        ${JinxAnimation768} 768w,
        ${JinxAnimation960} 960w,
        ${JinxAnimation1024} 1024w,
        ${JinxAnimation1280} 1280w
   `}
    type="image/webp"></source>
        <JinxImage src={JinxSwoopImage} alt="A swashbuckling cat deformed using Contour Rig Tools"/>
    </JinxPicture>
  </JinxContainer>
);

const JinxAnimationBand = () => (
  <Band background={siteColors.darkest}>
    <BandTextContainer>
      <BandTextHeader>
        Real Bones, With Length and Stretch
      </BandTextHeader>
      <BandBodyText>
          Contour's joint chains consist of both joints and bones, separating concerns of bone
          length from joint translation. Keep joints and bones in a hierarchy to take advantage
          of Contour Bones&rsquo; built-in length and stretch, or break the hierarchy to create
          any rig structure you like.
      </BandBodyText>
      <BandTextHeader>
        Spline-Based Joint Chains
      </BandTextHeader>
      <BandBodyText>
          Since every Contour joint chain generates a spline, flexible rig behavior
          is always at your fingertips. Contour Bone CVs act as additional control points for
          the spline, and can be added to any bone at any time.
      </BandBodyText>
    </BandTextContainer>
    <JinxWireframe />
  </Band>
);

export default JinxAnimationBand;
