import React from "react";
import styled from "styled-components";
import { siteColors } from "../constants/colors";
import breakpoints from "../constants/breakpoints";
import JinxSwoopImage from "../images/jinxwire/jinx_swoop.png";
import {
  Band,
  BandTextContainer,
  BandTextHeader,
  BandBodyText,
} from "./band";

const JinxSwoopContainer = styled.div`
  width: 100%;
  line-height: 0;
  display: flex;
  justify-content: center;
  padding-top: 1em;
  margin-bottom: 2em;
  padding-left: 1em;
  max-height: 512px;
`;

const JinxImage = styled.img`
  display: block;
  width: 640px;
  height: 480px;
  min-width: 720px;
  min-height: 512px;
  @media (max-width: ${ breakpoints.iPad }px) {
     width: 480px;
     height: 360px;
     min-width: 480px;
     min-height: 360px;
  }
  @media (max-width: ${ breakpoints.xLargePhone }px) {
     width: 320px;
     height: 240px;
     min-width: 320px;
     min-height: 240px;
  }
`;

const JinxSwoop = () => (
  <JinxSwoopContainer>
    <JinxImage src={JinxSwoopImage} width="100%" />
  </JinxSwoopContainer>
);

const ThirdBand = () => (
  <Band background={siteColors.darkest} >
    <BandTextContainer>
      <BandTextHeader>
        Unparalleled Deformation Quality
      </BandTextHeader>
      <BandBodyText>
        <p>
          Contour's volume-aware spline deformation produces perfect bend and twist,
          without pinching or candy-wrappering.
        </p>
        <p>
          The Contour deformer
          automatically spreads the mesh to create fair and even curvature inside
          and outside acute bends in the spline, even for very thick meshes, and provides high-level
          controls for tuning deformation. Stable twist along the spline comes for free.
        </p>
      </BandBodyText>
    </BandTextContainer>
    <JinxSwoop />
  </Band>
);

export default ThirdBand;
