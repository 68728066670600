import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { siteColors } from "../constants/colors";
import breakpoints from "../constants/breakpoints";
import { InternalLink, EmailLink } from "./sitelinks";
import {
  Band,
  BandTextContainer,
  BandTextHeader,
  BandBodyText,
} from "./band";

const PirateBackgroundImage = ({ className, children, gradient }) => {
    const { pirate } = useStaticQuery(
        graphql`
          query {
              pirate: file(relativePath: { eq: "pirate_with_parrot_lowered.jpg" }) {
                  childImageSharp {
                      fluid(quality: 90, maxWidth: 4160) {
                          ...GatsbyImageSharpFluid
                      }
                  }
              }
          }
        `
    );

    const pirateImageData = pirate.childImageSharp.fluid;

    return (
            <BackgroundImage
        className={className}
        fluid={pirateImageData}
        role="img"
            >
            {children}
        </BackgroundImage>
    );
};

const PirateAndParrotContainer = styled(PirateBackgroundImage)`
  width: 100%;
  height: 540px;
  min-height: 540px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @media (max-width: ${ breakpoints.xLargePhone }px) {
    height: 480px;
    min-height: 480px;
  }
`;

const CallTextContainer = styled.div`
  @media (max-width: ${ breakpoints.desktopSmallHD }px) {
    padding-top: 6em;
    padding-bottom: 3em;
  }
`;

const CallBand = () => (
        <Band background={siteColors.darker} rowHeight="540px"  columnHeight="100%" reverse>
    <PirateAndParrotContainer />
        <BandTextContainer backgroundColor={siteColors.darker}>
        <CallTextContainer>
      <BandTextHeader>
        Get Contour Rig Tools
      </BandTextHeader>
      <BandBodyText>
        <p>
        Download a 30-day trial of Contour from our <InternalLink to="/software">Software page</InternalLink>.
        </p>

        <p>
        Contour is available on a subscription basis. We're still working on integrating payment into our site, but you can purchase Contour by getting in touch with us at <EmailLink href="mailto: sales@notionalpipe.com">sales@notionalpipe.com</EmailLink>.</p>

       <p>More information about pricing and Contour licensing is available on our <InternalLink to="/software">Software page</InternalLink>.</p>
      </BandBodyText>
    </CallTextContainer>
    </BandTextContainer>
  </Band>
);

export default CallBand;
