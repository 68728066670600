import React from "react";
import styled from "styled-components" ;
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { siteColors } from "../constants/colors";
import Monkey from "../components/monkey";
import JinxAnimationBand from "../components/jinxanimationband";
import SecondBand from "../components/secondband";
import ThirdBand from "../components/thirdband";
import FourthBand from "../components/fourthband";
import MonsterBand from "../components/monsterband";
import MainFeatureCards from "../components/mainfeaturecards";
import CallBand from "../components/callband";
import { Band } from "../components/band";

const CreditText = styled.div`
  color: ${ siteColors.light };
  font-size: 0.75em;
  margin-left: 0.5em;
  margin-top: 0.25em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
`;

const ElectricAnimalsCreditLink = styled.a`
   color: ${ siteColors.lightAccent };
   text-decoration: none;
`;

const IndexPage = (props) => (
  <Layout>
    <SEO title="Home" />
    <Monkey />
    <Band background={siteColors.darkest} >
      <CreditText>
        &ldquo;Monta The Monkey&ldquo; Test for Vintata Animation Studio, Vietnam
      </CreditText>
    </Band>
    <Band rowHeight="0em" columnHeight="3em" background={siteColors.darkest} />
        <SecondBand data={props.data} />
        <Band rowHeight="1em" background={siteColors.darkest} />
    <JinxAnimationBand />
    <Band rowHeight="2em" background={siteColors.darkest} />
    <MonsterBand />
    <Band background={siteColors.darkest} >
      <CreditText>
        Image from &ldquo;New Pioneers&ldquo; Courtesy Bit Films
      </CreditText>
    </Band>
    <Band background={siteColors.darkest} rowHeight="2em" columnHeight="5em"/>
    <ThirdBand />
    <Band background={siteColors.darkest} rowHeight="2em" columnHeight="5em"/>
        <FourthBand data={props.data} />
    <MainFeatureCards />
    <Band rowHeight="6em" columnHeight="6em" background={siteColors.darkest} />
    <CallBand />
        <Band background={siteColors.darkest} >
        <CreditText>
        Image from &ldquo;Pirate and Parrot&ldquo; © <ElectricAnimalsCreditLink href="http://electricanimals.fun" rel="noreferrer" target="_blank">Electric Animals</ElectricAnimalsCreditLink>
    </CreditText>
        </Band>
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query {
    acrobat720: file(relativePath: { eq: "acrobat/acrobat.png" }) {
      childImageSharp {
        fluid(sizes: "(max-width: 604px) 360px, (max-width: 768px) 540px, (max-width: 1506px) and (min-width: 1317px) 540px, 720px",

 srcSetBreakpoints: [360, 540, 720]) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    painting720: file(relativePath: { eq: "paintingScreenshot/paintingScreenshot.png" }) {
      childImageSharp {
        fluid(sizes: "(max-width: 604px) 360px, (max-width: 768px) 540px, (max-width: 1506px) and (min-width: 1317px) 540px, 720px",

 srcSetBreakpoints: [360, 540, 720]) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
