import React from "react";
import styled, { css } from "styled-components";
import { siteColors } from "../constants/colors";
import breakpoints from "../constants/breakpoints";

const Band = styled.div`
  height: ${ props => props.rowHeight };
  background: ${props =>  props.background };
  display: flex;
  @media (max-width: ${ breakpoints.desktopSmallHD }px) {
    flex-direction: ${ props => props.reverse ? "column-reverse" : "column" };
    height: ${ props => props.columnHeight ? props.columnHeight : props.rowHeight };
  }
`;

const SimpleBackgroundImageBand = styled(Band)`
${ props =>
   props.backgroundImage &&
   css`
background-size: cover;
background-repeat: no-repeat;
background-position: left bottom;
`};
  }

${ props =>
   props.backgroundImage && !props.backgroundGradient &&
   css`
background-image: url(${ props.backgroundImage });
`};
  }

${ props =>
   props.backgroundImage && props.backgroundGradient &&
   css`
background-image: ${props.backgroundGradient}, url(${ props.backgroundImage });
`};

`;


const BandTextOuterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
${ props =>
   props.backgroundColor &&
   css `
      background-color: ${ props.backgroundColor };
   `};
`;

const BandTextInnerContainer = styled.div`
  color: ${ siteColors.light };
  max-width: 550px;
  @media (max-width: ${ breakpoints.xLargePhone }px) {
    max-width: 360px;
  }
  @media (max-width: ${ breakpoints.mediumPhone - 1 }px) {
    max-width: 320px;
  }
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
`;

const BandTextContainer = (props) => (
        <BandTextOuterContainer backgroundColor={props.backgroundColor}>
        <BandTextInnerContainer>
        { props.children }
    </BandTextInnerContainer>
        </BandTextOuterContainer>
);

const BandTextHeader = styled.div`
  font-size: 2em;
  margin-bottom: 0.25em;
  @media (max-width: ${ breakpoints.desktopMediumHD }px) and (min-width: ${ breakpoints.desktopSmallHD + 1}px) {
    font-size: 1.6em;
  }
  @media (max-width: ${ breakpoints.iPad }px) {
    font-size: 1.6em;
  }
`;

const BandBodyText = styled.div`
   font-size: 1.2em;
   padding-bottom: 2em;
  @media (max-width: ${ breakpoints.desktopMediumHD }px) and (min-width: ${ breakpoints.desktopSmallHD + 1}px) {
    font-size: 1em;
  }
  @media (max-width: ${ breakpoints.iPad }px) {
    font-size: 1em;
  }
`;


const BandList = styled.ul`
   padding-top: 2em;
`;
const BandListItem = styled.li``;

export {
    Band,
    SimpleBackgroundImageBand,
    BandTextContainer,
    BandTextHeader,
    BandBodyText,
    BandList,
    BandListItem
};
