import React from "react";
import styled from "styled-components";
import Img from 'gatsby-image';
import { siteColors } from "../constants/colors";
import breakpoints from "../constants/breakpoints";
import{
    Band,
    BandTextContainer,
    BandTextHeader,
    BandBodyText,
} from "./band";

const ImageContainer = styled.div`
  width: 100%;
  line-height: 0;
  display: flex;
  justify-content: center;
  padding-top: 3em;
  padding-bottom: 2em;
  padding-left: 1em;
  @media (max-width: ${ breakpoints.desktopSmallHD }px) {
  padding-left: 4em;
  padding-right: 4em;
  }
`;

const InnerContainer = styled.div`
  width: 720px;
  height: 512px;
  min-width: 720px;
  min-height: 512px;
  @media (max-width: ${ breakpoints.desktopMediumHD }px) and (min-width: ${ breakpoints.desktopSmallHD + 1}px) {
     width: 540px;
     height: 384px;
     min-width: 540px;
     min-height: 384px;
  }
  @media (max-width: ${ breakpoints.iPad }px) {
     width: 540px;
     height: 384px;
     min-width: 540px;
     min-height: 384px;
  }
  @media (max-width: ${ breakpoints.xLargePhone }px) {
     width: 360px;
     height: 256px;
     min-width: 360px;
     min-height: 256px;
  }
`;

const Image = ({ imageData }) => (
    <ImageContainer>
        <InnerContainer>
      <Img fluid={imageData} alt="Contour Rig Tools weight painting tool" />
        </InnerContainer>
    </ImageContainer>
);

const FourthBand = (props) => (
    <Band background={siteColors.darkest} reverse>
        <Image imageData={props.data.painting720.childImageSharp.fluid} alt="The Contour Rig Tools weight painting tool" />
        <BandTextContainer>
            <BandTextHeader>
                Simplified Weight Painting
        </BandTextHeader>
            <BandBodyText>
                <p>
                    Each Contour joint chain generates a spline that acts as a single
                    influence for the Contour deformer, drastically reducing the need
                    for careful weight painting. Many limbs in a Contour character may
                    be weighted 100% to a single influence.
                </p>
                <p>
                    When you do need to paint weights in the areas between
    limbs, for instance where an arm meets the body, Contour&rsquo;s weight painting UI and component
    editor will feel familiar to anyone who has used Maya&rsquo;s tools.
                </p>
            </BandBodyText>
        </BandTextContainer>
    </Band>
);

export default FourthBand;
