import React from "react";
import styled from "styled-components";
import MonkeyVideo1920 from "../video/monkey_1920.mp4";
import MonkeyVideo1024 from "../video/monkey_1024.mp4";
import MonkeyVideo768 from "../video/monkey_768.mp4";
import MonkeyPoster1920 from "../images/monkey-posters/monkey_1920-40.jpg";
import MonkeyPoster1024 from "../images/monkey-posters/monkey_1024-40.jpg";
import MonkeyPoster768 from "../images/monkey-posters/monkey_768-40.jpg";
import { siteColors } from "../constants/colors";
import breakpoints from "../constants/breakpoints";
import { useViewport } from "../utils/viewport-hook";

const MonkeyContainer = styled.div`
  width: 100%;
  background: ${siteColors.darkest};
  position: relative;
line-height: 0;
`;

const GradientDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient( to bottom, rgba(9, 20, 3, 0) 0%, rgba(9, 20, 3, 0.0) 42%, rgba(9, 20, 3, 0.4) 55%, rgba(9, 20, 3, 0.5) 60%, rgba(9, 20, 3, 0.5) 80%, rgba(9, 20, 3, 0.25) 100% );
  z-index: 5;
`;

const TextContainer = styled.div`
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${siteColors.light};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
line-height: 1.43;
  z-index: 10;
width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 6em;
  padding-bottom: 6em;
  width: 100%;
`;

const HeaderText = styled.div`
   white-space: nowrap;
   font-size: 4.0em;
  @media (max-width: ${ breakpoints.desktopSmallHD }px) {
    font-size: 3.0em;
  }
  @media (max-width: ${ breakpoints.iPad }px) {
    font-size: 2.4em;
  }
  @media (max-width: ${ breakpoints.xLargePhone }px) {
    font-size: 1.6em;
  }
  @media (max-width: ${ breakpoints.mediumPhone }px) {
    font-size: 1.6em;
  }
`;

const CopyText = styled.div`
    font-size: 2.0em;
  @media (max-width: ${ breakpoints.desktopSmallHD }px) {
    font-size: 1.4em;
  }
  @media (max-wid4h: ${ breakpoints.iPad }px) {
    font-size: 1.4em;
  }
  @media (max-width: ${ breakpoints.xLargePhone }px) {
    font-size: 1.0em;
  }
  @media (max-width: ${ breakpoints.mediumPhone }px) {
    font-size: 1.0em;
  }
`;

const videoSrc = width => {
    if (width > 1024) {
        return MonkeyVideo1920;
    }

    if (width > 768) {
        return MonkeyVideo1024;
    }

    return MonkeyVideo768;
};

const videoPoster = width => {
    if (width > 1024) {
        return MonkeyPoster1920;
    }

    if (width > 768) {
        return MonkeyPoster1024;
    }

    return MonkeyPoster768;
};


const MonkeyVideo = ({ width }) => (
        <video width="100%" autoPlay muted loop key={1920} poster={videoPoster(width)} >
        <source src={videoSrc(width)} type="video/mp4" />
        </video>
);

const Monkey = () => {
    const width = useViewport().width;

    return (
            <MonkeyContainer>
            <GradientDiv />
            <MonkeyVideo width={width} />
            <TextContainer>
            <InnerContainer>
            <div>
            <HeaderText>
            Introducing Contour Rig Tools
        </HeaderText>
            <CopyText>
            Revolutionary spline-based rigging for Maya
        </CopyText>
            </div>
            </InnerContainer>
            </TextContainer>
            </MonkeyContainer>
    );
 };

export default Monkey;
